
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.bt {
  background: transparent;
  border: 1px solid $light;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 18px !important;
  transition: $trns;
  gap: 12px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;

  @media screen and (max-width: 774px) {
    width: 100%;
  }

  .v-spinner .v-beat {
    background-color: #f36e2a !important;
  }

  &:active {
    //background: unset;
  }


  svg {
    transition: $trns;
    fill: $gray;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    color: $gray;
    transition: $trns;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid $light;
    box-shadow: unset;

    span {
      color: $gray;
    }

    svg {
      fill: $gray;
    }
  }

  &-primary {
    &.non-shadow {
      box-shadow: none !important;
    }

    .v-spinner .v-beat {
      background-color: #f36e2a !important;
    }

    &:not(:disabled) {
      background: white;
      border-color: $primary;
      box-shadow: 0px 8px 40px -12px rgba(243, 110, 42, 0.3);

      span,
      svg {
        color: $primary;
      }

      svg {
        fill: $primary;
      }

      &:hover {
        background: $primary;
        border-color: transparent;

        .v-spinner .v-beat {
          background-color: #fff !important;
        }

        span,
        svg {
          color: $white;
        }

        svg {
          fill: $white;
        }
      }
    }
  }

  &-golden {
    background: #f3d32a;
    border: 1px solid #f3d32a;
    box-shadow: 0 11px 22px -12px #f3d32a;

    .v-spinner .v-beat {
      background-color: #fff !important;
    }

    &:hover {
      background: black;
      border: none;
      box-shadow: 0px 11px 22px -12px rgba(0, 0, 0, 0.5);

      span {
        color: #f3d32a;
      }

      svg {
        fill: #f3d32a;
      }
    }

    span {
      color: $dark;
    }

    svg {
      fill: $dark;
    }
  }

  &-danger {
    border: 1px solid $primary;
    background: $white;

    .v-spinner .v-beat {
      background-color: $danger !important;
    }

    span {
      color: $primary;
    }

    svg {
      fill: $primary;
    }

    &:hover {
      background: $danger;

      //border-color: $white;
      .v-spinner .v-beat {
        background-color: white !important;
      }

      span {
        color: $white;
      }

      svg {
        fill: $white;
      }
    }
  }

  &-success {
    border: 1px solid #63c700;
    background: $white;

    span {
      color: $success;
    }

    svg {
      fill: $success;
    }

    &:hover {
      background: $success;

      span {
        color: $white;
      }

      svg {
        fill: $white;
      }
    }
  }

  &-warning {
    background: $warning;
    border: 0;

    span {
      color: $dark;
    }

    svg {
      fill: $dark;
    }

    &-dark {
      span {
        color: $dark;
      }
    }

    &:hover {
      background: $dark;
      box-shadow: 0 11px 22px -12px rgba(0, 0, 0, 0.5);

      span {
        color: $warning;
      }

      svg {
        fill: $warning;
      }
    }
  }

  &-bg-danger {
    background: $danger;
    border-color: transparent;

    .v-spinner .v-beat {
      background-color: #fff !important;
    }

    span {
      color: $white;
    }

    svg {
      fill: $white;
    }

    &:hover {
      background: $secondary;
    }
  }

  &-bg-primary {
    background: $primary;
    border-color: transparent;
    color: white;

    .v-spinner .v-beat {
      background-color: #fff !important;
    }

    span {
      color: $white;
    }

    svg {
      fill: $white;
    }

    &:hover {
      background: $secondary;
    }
  }

  &-transparent {
    background: none;
    border: 0;
    color: $gray;

    &:hover {
      background: none;
      border: 0;
      color: $gray;

      svg {
        fill: $primary;
      }
    }

    &-danger {
      span {
        color: $primary;
      }

      svg {
        fill: $primary;
      }
    }
  }

  &-close {
    span {
      color: $white;
    }

    svg {
      fill: $white;
    }
  }

  &-gray {
    background: #eff0f2;
    color: #384456;

    .v-spinner .v-beat {
      background-color: #384456 !important;
    }

    &:hover {
      background: white;
    }
  }

  //&-bg-gray {
  //  background-color: $light;
  //  color: $gray;
  //  svg {
  //    fill: $gray;
  //  }
  //}
  &-light {
    background: $light;
    border: 0;

    span {
      color: $gray;
    }

    svg {
      fill: $gray;
    }

    &:hover {
      span {
        color: $primary;
      }

      svg {
        fill: $primary;
      }
    }
  }

  &-transparent-bg {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);

    span {
      color: $white;
    }

    svg {
      fill: $white;
    }

    &:hover {
      background: $primary;
    }
  }

  &-hover-warning:hover {
    .v-spinner .v-beat {
      background-color: #25d366 !important;
    }

    span {
      color: $warning;
    }

    svg {
      fill: $warning;
    }
  }

  &-hover-bg-white:hover {
    background-color: $white;

    span {
      color: $primary;
    }

    svg {
      fill: $primary;
    }
  }
}