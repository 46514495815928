
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.modal-wrapper {
  &.login-modal {
    .modal {
      min-width: unset;
    }
  }

  &.campaign-detail {
    .modal {
      .modal__header {
        margin-bottom: 30px;
      }

      .action-buttons {
        //flex-direction: column;
      }
    }
  }

  &.donate-modal {
    .modal {
      min-width: unset;
      padding-bottom: 30px;

      .bt-close:hover {
        background-color: $danger;

        span {
          color: $white;
        }

        svg {
          fill: $white;
        }
      }

      .modal-body {
        padding: 0 30px;

        .form-wrapper {
          width: 100%;

          .price-area .item {
            width: calc(50% - 3px);
          }
        }
      }
    }
  }

  &.named-water-donate-modal {
    .modal {
      @media screen and (max-width: 992px) {
        width: 600px;

        .modal__header {
          width: 15%;
          margin-bottom: 15px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .modal-body {
          .header-wrapper {
            gap: 15px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        width: 500px;
      }

      @media screen and (max-width: 576px) {
        width: 350px;

        .modal__header {
          width: 7%;
          margin-bottom: 15px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .modal-body {
          gap: 30px;

          .header-wrapper {
            gap: 5px;

            &::before {
              display: none;
            }

            h4 {
              font-size: 18px !important;
            }

            ul {
              flex-direction: row;
              justify-content: space-between;

              li {
                width: 50%;

                button {
                  width: 100%;
                }
              }
            }
          }

          .footer {
            .content-wrapper {
              .tab-content {
                .tab-pane {
                  .inputs-line {
                    flex-direction: column;

                    .drop-wrapper {
                      .multiselect {
                        height: 100%;
                      }
                    }

                    .input-wrapper {
                      input {
                        height: 48px;
                      }
                    }

                    .drop-wrapper,
                    .total-wrapper,
                    .input-wrapper {
                      width: 100%;
                      height: 40px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal-body {
      gap: 60px;

      .footer {
        .content-wrapper {
          .tab-content {
            .tab-pane {
              .inputs-line {
                .drop-wrapper {
                  height: 56px !important;

                  svg {
                    top: 40%;
                  }

                  .multiselect {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .header-wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include dashed-border(-30px);

        .radio-buttons {
          justify-content: center;

          button {
            width: 150px;
          }
        }
      }

      .footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tab-pane {
          width: 100%;
          display: flex;
          flex-direction: column;

          .inputs-line {
            display: flex;
            gap: 16px;

            > * {
              width: 50%;
            }

            .multiselect .multiselect__tags {
              background: $lighter;
            }

            .total-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 20px 18px;
              background: $light;

              p {
                margin-bottom: 0;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: -0.03em;
              }
            }
          }

          > p {
            margin: 16px 0 6px;
          }
        }

        > .bt {
          width: max-content;
          margin-top: 16px;
        }
      }
    }
  }

  &.my-campaign-modal {
    .modal {
      .header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        span {
          display: block;
          background: rgba($warning, 0.1);
          padding: 8px;
          width: max-content;
        }
      }

      .bt-close {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);

        span {
          color: $white;
        }

        svg {
          fill: rgba($white, 0.4);
        }

        &:hover {
          background: $danger;
        }
      }

      .modal-body {
        margin-bottom: 30px;

        .form-group {
          margin-bottom: 0;
        }
      }

      .check-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;

        a {
          text-decoration: none;
        }
      }
    }
  }

  &.water-donate-modal {
    .modal {
      padding-bottom: 0;

      .modal__header {
        margin-bottom: 0;
      }

      .modal-body {
        padding: 0 30px;
        margin: 60px 0 30px;

        .block-header {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .chart-wrapper {
            width: 90px;

            .chart {
              height: 100%;

              .text {
                font-size: 16px;
                transform: translate(-4px, 11px);
              }
            }
          }
        }

        .inputs-wrapper {
          display: flex;
          flex-direction: column;
          gap: 6px;
          align-items: flex-end;

          .bt {
            margin-top: 10px;
          }

          .multiselect {
            height: 58px;
            margin-bottom: 10px;
          }

          .input-wrapper {
            position: relative;
            transition: $trns;
            margin-top: 10px;

            input {
              padding: 12px 36px 12px 16px;
              width: 100%;
              border: 1px solid $light;
              background: $lighter;
              color: $gray;
              transition: $trns;

              &:focus,
              &.valid {
                outline: none;
                border: 1px solid $primary;
                color: $dark;
                background: white;

                & + svg {
                  fill: $primary;
                }
              }

              &:disabled {
                background: #eff0f2;
                border: 1px solid #eff0f2;
                cursor: not-allowed;
                user-select: none;
              }
            }

            a {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
            }
          }
        }

        .form-wrapper {
          width: 100%;
          padding-bottom: 0;
          margin-bottom: 10px;

          &::before {
            content: unset;
          }
        }
      }
    }
  }

  &.sacrifice-modal {
    .modal {
      padding-bottom: 0;

      .modal-body {
        gap: 16px;
        padding: 0 30px 30px;
        width: 100%;

        .content-wrapper {
          width: 100%;
        }

        #tab-8,
        #tab-9 .inputs-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;

          .multiselect {
            height: 58px;
          }

          .inputs {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .input-wrapper input {
              background: #fbfbfb !important;
              border: 1px solid #eff0f2 !important;
            }
          }

          .custom-checkbox {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.spring-modal {
    .modal {
      min-width: unset;
      padding-bottom: 30px;

      .modal-body {
        .header {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: 100%;
          @include dashed-border(-15px);
        }

        .prices-wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: 100%;

          .form-wrapper {
            gap: 5px;
            padding-bottom: 0;
            @include dashed-border(-15px);

            .price-area {
              .item {
                font-size: 16px;
                line-height: 34px;
              }
            }
          }
        }

        .inputs-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;

          .bt {
            margin-top: -1px;
          }

          .textarea-wrapper {
            textarea {
              height: 120px;
            }
          }
        }
      }
    }
  }

  &.code-inputs {
    .modal {
      .modal__header {
        margin-bottom: 30px;
      }

      .code-inputs {
        .inputs {
          margin-top: 0;
          align-items: stretch;
          width: 100%;

          input {
            width: inherit;
          }
        }
      }
    }
  }

  &.spring-account-modal {
    .modal {
      #modalTitle {
        margin-bottom: 30px;
      }

      p {
      }
    }
  }
}
