
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.auto-closebar{
  height: 4px;
  background-color: $primary;
  border-radius: 5px ;
  margin-top: 5px;
  transition: .55s ease-in-out;
}
