.drop-wrapper {
  position: relative;
  border: 1px solid #EFF0F2;
  height: fit-content;

  &.fluid {
    width: 100%;
  }

  .multiselect {
    height: 46px;

    .multiselect-input {
      height: 46px;
      border: none !important;

      .multiselect-caret {
        display: none;
      }
    }

    .multiselect-options {
      overflow-y: scroll !important;

      .is-selected {
        background: $primary !important;
      }
    }
  }

  svg {
    position: absolute;
    top: 36%;
    right: 16px;
  }
}