
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.dropdown-handler {
  position: relative;
  .dropdown-list {
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
    top: 100%;
    min-width: 200px;
    right: 0;
    border: 1px solid $light;
    background: $lighter;
    box-shadow: $main-card-shadow;
    .drop-item {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover,
        &.router-link-active {
          color: $primary;
          svg {
            fill: $primary;
          }
        }
        &,
        svg {
          color: $gray;
          transition: $trns;
        }
      }
      &:not(:last-child) {
        padding-bottom: 15px;
        border-bottom: 1px solid $lighter;
      }
    }
  }
}
