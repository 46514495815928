.login {
  .login-side {
    ul {
      .nav-text {
        transition: all 300ms ease-in-out;

        &:hover {
          color: #F36E2A;
        }
      }
    }
  }
}