//Design Colors
$primary: #f36e2a;
$secondary: #39aac4;

//System Colors
$dark: #000000;
$gray: #384456;
$light: #eff0f2;
$lighter: #fbfbfb;
$white: #fff;

// Action Colors
$info: #00c8f4;
$success: #63c700;
$warning: #f3d32a;
$danger: #ff6161;

//Shadows
$main-card-shadow: 0 25px 40px -15px #eff0f2;
$btn-shadow: 0 25px 40px -15px rgba(56, 68, 86, 0.06);
//Button
$primary-btn-shadow: 0 11px 22px -12px #f36e2a;
$secondary-btn-shadow: 0 11px 22px -12px rgba(57, 170, 196, 0.2);

$font-base-family: SourceSansPro;
$font-base-size: 16px;
$font-base-weight: 400;

$trns: 0.3s ease all;

body {
  font-family: $font-base-family;
  overflow-x: hidden;
}

h1 {
  @include font(54px, 54px, 400);
  letter-spacing: -0.04em;
  @media screen and (max-width: 576px) {
    @include font(44px, 44px, 400);
  }
}

h2 {
  @include font(38px, 44px, 400);
  letter-spacing: -0.02em;
  @include font(32px, 36px, 400);
}

h3 {
  @include font(24px, 34px, 300);
  letter-spacing: -0.03em;
  @include font(22px, 32px, 400);

}

h4 {
  @include font(24px, 26px, 400);
  letter-spacing: -0.02em;
  @include font(22px, 18px, 400);
}

h5 {
  @include font(20px, 24px, 500);
}

h6 {
  @include font(16px, 34px, 500);
}

h1,
h2 {
  font-family: Zodiak, $font-base-family;
  margin-bottom: 0;
}

h3,
h4,
h5,
h6 {
  font-family: $font-base-family;
  margin-bottom: 0;
}

// font sizes
.nav-text {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  font-weight: 400;
  font-family: $font-base-family;
}

p {
  font-size: 16px;
  line-height: 34px;
  font-family: $font-base-family;
  margin-bottom: 0;
  font-weight: 400;
}

.span-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: -0.03em;
  font-family: $font-base-family;
}

.btn-text {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  font-family: $font-base-family;
  font-weight: 600;
}

.abs-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 600;
}

.regular {
  font-weight: 500;
}

.wh-light {
  font-weight: 400;
}

.mb-30 {
  margin-bottom: 30px;
}

.plus30 {
  margin-top: 33px;
}

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.gray {
  color: $gray;
}

.dark {
  color: $dark;
}

.success {
  color: $success;
}

.warning {
  color: $warning;
}

.danger {
  color: $danger;
}

.white {
  color: $white;
}

.second-font {
  font-family: Zodiak, $font-base-family !important;
  @media screen and (max-width: 774px) {
    text-align: center;
  }
}

.modal-text {
  text-align: center;
}

.main-shadow {
  box-shadow: $main-card-shadow;
}

.primary-shadow {
  box-shadow: $primary-btn-shadow;
}

.title-badge {
  color: $primary;
  background: rgba($primary, 0.1);
  padding: 8px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: inline-block;
}

.warning-badge {
  color: $warning;
  background: rgba($warning, 0.1);
  padding: 8px;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
}

.danger-badge {
  color: $danger;
  background: rgba($danger, 0.1);
  padding: 8px;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
}

.finished-badge {
  color: $primary;
  background: rgba($primary, 0.1);
  padding: 8px;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
}

.section-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .title-badge {
      margin: 0;
    }
  }

  > * {
    text-align: center;
  }

  h2 {
    color: #000;
    font-family: "Zodiak", sans-serif;
  }

  h4 {
    color: #000;
    font-family: "Zodiak", sans-serif;
  }
}

.detail-list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  li {
    font-size: 16px;
    line-height: 34px;

    &::before {
      content: "";
      background-image: url("@/assets/images/icons/grid-2.svg");
      display: inline-block;
      background-size: contain;
      background-position: center;
      width: 14px;
      height: 16px;
      margin-right: 15px;
    }
  }
}

.project-wrapper {
  .title {
    margin-bottom: 120px;
  }
}

.project-detail-wrapper {
  margin: 120px 0;

  .project-detail {
    margin-bottom: 120px;
  }
}

//Pagination style
.pagination {
  gap: 5px;
  margin-top: 30px !important;

  .page-item {
    //TODO: Fix icon
    &:first-child .page-link::before {
      //content: '\f324';
      background: red;
    }
    &:last-child .page-link::before {
      //content: '\f324';
    }

    &:first-child,
    &:last-child {
      .page-link {
        //color: transparent;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          color: $gray;
        }
      }
    }

    .page-link {
      width: 54px;
      height: 54px;
      background-color: $lighter;
      border: 1px solid $light;
      color: $gray;
      transition: $trns;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 !important;
      &:hover {
        transition: $trns;
        border-color: $secondary;
        background-color: $lighter;
        color: #39aac4;
        box-shadow: unset;
      }
    }

    &.active {
      .page-link {
        border-color: $secondary;
        background-color: $lighter;
        color: #39aac4;
        box-shadow: unset;
      }
    }
  }
}

@mixin text-shorten($numLines: 1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $numLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
  }
}

.splide__track {
  //overflow: visible !important;
}

.splide__arrow,
.splide__pagination {
  display: none !important;
}

.fr-view {
  > * {
    margin-bottom: 30px;
  }

  img {
    width: 100%;
  }

  tr th {
    padding-bottom: 10px;

    div {
      line-height: 34px;
      color: $gray;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  tr td {
    padding: 10px 0.5rem;

    div {
      font-size: 16px;
      line-height: 34px;
      color: $gray;
      white-space: nowrap;
    }
  }

  thead tr th,
  tbody tr:not(:last-child) td {
    border-bottom-style: dashed;
  }

  tbody tr:last-child td {
    border-bottom-width: 0;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      letter-spacing: -0.04em;
      color: $gray;
      padding: 15px 0;
      margin-left: 30px;
      font-weight: $font-base-weight;
      font-size: $font-base-size;
      line-height: 22px;

      &:before {
        content: "";
        background-image: url("@/assets/images/icons/grid-2.svg");
        position: absolute;
        left: -30px;
        top: 15px;
        width: 14px;
        height: 16px;
      }
    }
  }
}

.radio-buttons {
  display: flex;

  .custom-bt {
    width: 100%;
    background: rgba(57, 170, 196, 0.1);
    backdrop-filter: blur(6px);
    color: $secondary;
    transition: $trns;
    font-size: 14px;
    line-height: 16px;
    padding: 12px;
    border: 0;
    border-radius: 0;

    &.active,
    &:hover {
      background-color: $secondary;
      color: $white;
    }
  }
}

.blogs-wrapper {
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  .blog-card {
    min-width: 360px;
    width: calc((100% / 3) - 20px);
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
}

.campaigns-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.terminate-project {
  background-color: $light;
  padding: 16px 18px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: $trns;
  cursor: pointer;

  p {
    transition: $trns;
  }

  &:hover {
    transition: $trns;
    background: $light;
    color: #39AAC4;
    p {
      color: #39AAC4;
      transition: $trns;
    }
  }

  div {
    display: flex;
    gap: 12px;
  }
}

.one-line {
  white-space: nowrap; /* Metni tek satırda tutar ve alt satıra geçmesini önler */
  overflow: hidden; /* İçeriğin kutunun dışına taşmasını engeller */
  text-overflow: ellipsis;
}
.two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}