
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

html {
  .outer-nav-menu {
    @media screen and (min-width: 993px) {
      display: none;
    }
    height: 100vh;
    top: 0;
    left: 0;
    transform: translate3d(-240px, 0, 0);
    backface-visibility: hidden;
    background: #fff;
    font-family: Proxima Nova, sans-serif;
    font-size: 19px;
    z-index: 0;
    width: 240px;
    position: fixed;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    border-left: 1px solid #ebebeb;
    transition: 0.4s ease all;
    padding: 30px;
    display: flex;
    gap: 61px;
    flex-direction: column;

    img {
      width: 131px;
    }

    nav {
      // maybe fix delay
      display: block !important;
      // this is for the bottom one
      @include dashed-border(-30px);

      // this is for the top one
      &::after {
        content: "";
        position: absolute;
        background-position: bottom;
        width: 100%;
        height: 1px;
        background-repeat: repeat-x;
        top: -30px;
        background-image: linear-gradient(
          to right,
          rgba($gray, 0.2) 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-size: 8px 1px;
      }

      #mainNav {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media screen and (max-width: 992px) {
          gap: 24px;
        }

        .dropdown {
          transition: 0.3s ease all;

          &.open {
            .dropdown-menu {
              display: flex;
              border: 0;
              box-shadow: none;
              position: unset;
            }
          }
        }
      }
    }

    > .bt {
      margin: auto auto 0;
      width: 100%;
    }
  }

  .header-wrapper.subPage {
    .header-placeholder {
      height: 80px;
    }

    #header .header-body {
      height: 80px;

      .header-nav {
        min-height: unset;
      }

      .header-nav-features .header-nav-feature {
        &:first-child {
          border-right-color: $light;
        }

        > a {
          color: $dark;

          &:hover {
            color: $primary;
          }
        }

        button svg {
          fill: $secondary;
        }
      }
    }
  }

  #header {
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);

    &.spring .header-nav .header-nav-menu nav > ul > li > a {
      &:hover,
      &.router-link-active {
        color: $warning;

        &::before {
          background-color: $warning;
        }
      }
    }

    .header-body {
      height: 100px;
      border-bottom: 0;
    }

    .header-container {
      height: 100%;
      @media screen and (max-width: 992px) {
        padding: 0;
        margin: 0;
        max-width: unset;
      }
    }
  }

  &.oly-sticky-active {
    .header-wrapper.subPage {
      .header-placeholder {
        height: 50px;
      }

      #header {
        .header-body {
          height: 50px;
        }
      }
    }

    #header {
      &.spring .header-nav .header-nav-menu nav > ul > li > a {
        &:hover,
        &.router-link-active {
          color: $warning !important;

          &::before {
            background-color: $warning;
          }
        }
      }

      .header-nav .header-nav-menu nav > ul > li > a {
        &:hover,
        &.router-link-active {
          color: $primary !important;

          &::before {
            background-color: $primary;
          }
        }
      }

      .header-body {
        height: 50px;
      }
    }
  }
}
