
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.input-wrapper {
  width: 100% !important;
}

.multiselect-options {
  max-height: unset !important;
}

.swal2-container {
  z-index: 9999999999999999999999999 !important;
}
