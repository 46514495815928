
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

#footer .footer-wrapper .footer-body {
  .social-n-select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 554px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .select-wrapper {
      .custom-dropup {
        .dropdown-toggle {
          background-color: #fbfbfb;
          height: 50px !important;
          width: 96px;
          color: black;
          border: 1px solid #eff0f2;
          border-radius: 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          font-family: 'Source Sans Pro', sans-serif;

          &::after {
            position: relative;
            top: 4px;
            color: rgba($gray, .4);
            opacity: 0.4;
            right: -4px;
            content: "\f107";
            font-family: "Font Awesome 6 Free";
            border: none;
          }

          &.show {
            border: 1px solid $primary;
            color: $primary;

            &::after {
              content: '\f106';
              color: $primary;
            }
          }
        }

        .dropdown-menu {
          background-color: #fbfbfb;
          border: 1px solid #eff0f2;

          &.show {
            background: #FBFBFB;
            border: 1px solid #EFF0F2;
            box-shadow: 0px 25px 40px -15px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;
            border-radius: 0;
          }

          .dropdown-item {
            cursor: pointer;
            transition: $trns;
            padding: 0;

            &:not(:last-child) {
              border-bottom: 1px solid #EFF0F2;
              padding: 0 0 15px 0;
            }

            &:hover {
              background-color: transparent;
              color: $primary;
              transition: $trns;
            }
          }
        }
      }
    }
  }
}
