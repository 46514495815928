$header-type: transparent; // transparent or relative
$header-height: 100px;
$header-separator-color: rgba(255, 255, 255, 0.2);
$sticky-header-separator-color: rgba(255, 255, 255, 0.2);

//header//
$header-background-color: transparent;
$stick-header-background-color: #fff;

$header-border-top: 0px solid transparent;
$header-border-bottom: 1px solid rgba(236, 239, 242, 0.2);

//header nav menu//
$header-nav-menu-background-color: $white;
$sticky-header-nav-menu-background-color: $white;
$header-nav-menu-width: calc(100% - 30px);
$header-nav-menu-padding: 0;

//header nav dropdown list item link

$header-nav-link-color: $white;
$header-nav-link-active-color: $white;
$sticky-header-nav-link-color: $gray;
$sticky-header-nav-link-active-color: $primary;
$header-nav-link-radius: 0;
$header-nav-link-font-size: 14px;
$header-nav-link-font-weight: 400;
$header-nav-link-padding: 0 30px 0 0;
$header-nav-link-text-transform: capitalize;

// for li items below dropdown
$header-dropdown-list-item: 0;

$header-dropdown-link-color: $gray;
$header-dropdown-link-active-color: $primary;
$header-dropdown-link-bottom-line-color: rgba($light,.2);
$sticky-header-dropdown-link-color: $gray;
$sticky-header-dropdown-link-active-color: $primary;
$sticky-header-dropdown-link-bottom-line-color: rgba($light,1);
$header-dropdown-link-radius: 0;
$header-dropdown-link-font-size: $font-base-size;
$header-dropdown-link-font-weight: $font-base-weight;
$header-dropdown-link-padding: 0;
$header-dropdown-link-text-transform: uppercase;

$dropdown-submenu-icon-content: "\f105";
$dropdown-submenu-icon-size: 10px;
$dropdown-submenu-icon-color: $header-dropdown-link-color;
$dropdown-submenu-icon-active-color: $header-dropdown-link-active-color;
$sticky-dropdown-submenu-icon-color: $header-dropdown-link-color;
$sticky-dropdown-submenu-icon-active-color: $header-dropdown-link-active-color;


/*Mobile*/

$mobile-header-nav-menu-padding: 15px 0;

//header nav dropdown list item link
/*

$mobile-header-nav-link-color: $white;
$mobile-header-nav-link-radius: 0;
$mobile-header-nav-link-font-size: 14px;
$mobile-header-nav-link-font-weight: 400;
$mobile-header-nav-link-padding: 0.5rem  1rem;
$mobile-header-nav-link-text-transform: uppercase;
$mobile-header-nav-link-active-color: $white;
*/

// for li items below dropdown
$mobile-header-dropdown-list-item: 0 15px;

$mobile-header-dropdown-link-color: $dark;
$sticky-mobile-header-dropdown-link-color: $dark;
$mobile-header-dropdown-link-radius: 0;
$mobile-header-dropdown-link-font-size: calc(#{$header-dropdown-link-font-size} - 1px);
$mobile-header-dropdown-link-font-weight: $font-base-weight;
$mobile-header-dropdown-link-padding: 15px 0;
$mobile-header-dropdown-link-text-transform: uppercase;

$mobile-header-dropdown-toggle-icon-color: $mobile-header-dropdown-link-color;
$sticky-mobile-header-dropdown-toggle-icon-color: $mobile-header-dropdown-link-color;
$mobile-header-dropdown-toggle-icon-size: 10px;

/*Reverse Color For Page Header Except Index*/

//header//
$reverse-header-type: relative;

$reverse-header-background-color: transparent;
$reverse-stick-header-background-color: #fff;

//header nav menu//
$reverse-header-nav-menu-background-color: $white;
$reverse-sticky-header-nav-menu-background-color: $white;

//header nav dropdown list item link

$reverse-header-nav-link-color: $dark;
$reverse-header-nav-link-active-color: $dark;
$reverse-sticky-header-nav-link-color: $white;
$reverse-sticky-header-nav-link-active-color: $primary;
