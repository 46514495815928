
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

html {
  .header-placeholder {
    width: 100%;
    height: 135px;
    transition: $trns;
    opacity: 0;
  }

  #header {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
    img.user-avatar {
      margin: 0;
      padding: 0;
      border-radius: 100%;
    }
    &.white {
      background: $white;
      border-bottom: 1px solid $light;
    }

    &.dashboard-header {
      .header-nav-features .header-nav-feature {
        &:first-child {
          padding-right: 0;
          border-right: 0;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .dropdown-list {
          .drop-item {
            &:not(:last-child) {
              border-bottom: 1px solid #eff0f2;
            }

            a {
              padding: 0;
            }

            &:hover {
              a {
                background: transparent;
              }
            }

            &:last-child {
              a {
                &:hover {
                  color: $danger;
                }
              }
            }

          }
        }
      }
    }

    .header-body {
      height: 80px;
    }

    .header-container {
      height: 100%;
    }

    .header-nav-features {
      .header-nav-feature {
        gap: 10px;

        &.dropdown-handler {
          transition: $trns;
          cursor: pointer;
          .dropdown-list {
            transition: $trns;
          }
          &:hover {
            transition: $trns;
            .dropdown-list {
              transition: $trns;
              display: flex !important;
            }
          }
        }

        &:first-child {
          img {
            max-width: 54px;
            transition: $trns;
          }
        }

        .name-wrapper {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          p,
          h4 {
            margin-bottom: 0;
          }
          @media screen and (max-width: 500px) {
            h4 {
              width: 70px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        @media screen and (max-width: 992px) {
          &.dropdown-handler {
            display: flex;
          }
        }
      }
    }
  }

  &.oly-sticky-active {
    .header-placeholder {
      height: 105px;
    }

    #header {
      .header-body {
        height: 50px;
      }

      .header-nav-features {
        .header-nav-feature:first-child img {
          max-width: 40px;
        }
      }
    }
  }
}
