
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.header-placeholder {
  height: 100px;
}
#header.payment {
  .custom-nav {
    margin: 0 24px;
    .bt {
      background: none;
      border: none;
      height: 100%;
    }
  }
  @media screen and (max-width: 992px) {
    .outer-wrapper .left-nav {
      display: none !important;
    }
  }
  @media screen and (max-width: 500px) {
    .outer-wrapper {
      padding: 28px 12px;
    }
    .custom-nav {
      margin: 0 12px;
    }
    .bt.back-arr {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
