
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.inputs-wrapper {
  display: flex !important;
  flex-direction: column;
  gap: 18px;

  .input-wrapper {
    display: flex !important;
    flex-direction: column;
    gap: 18px;
  }

  .multiselect-input {
    height: 58px !important;
  }
}
