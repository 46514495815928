@mixin font-face($file, $weight: 400, $name: Gilroy, $path: "@/assets/fonts/") {
  @font-face {
    font-family: "#{$name}";
    src: url("#{$path}#{$file}.eot");
    src: url("#{$path}#{$file}.eot?#iefix") format("embedded-opentype"),
      url("#{$path}#{$file}.woff2") format("woff2"),
      url("#{$path}#{$file}.woff") format("woff"),
      url("#{$path}#{$file}.ttf") format("truetype"),
      url("#{$path}#{$file}.svg?#webfont") format("svg");
    font-weight: $weight;
    font-style: normal;
    font-display: swap;
  }
}

@mixin font($size: $font-base-size, $line: inherit, $weight: $font-size-base) {
  font-size: $size !important;
  line-height: $line !important;
  font-weight: $weight !important;
}

@mixin dashed-border($position: 0px, $dir: "y", $color: $gray, $ops: 0.2) {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-position: bottom;
    @if $dir == "y" {
      width: 100%;
      height: 1px;
      background-repeat: repeat-x;
      bottom: $position;
      background-image: linear-gradient(
        to right,
        rgba($color, $ops) 33%,
        rgba(255, 255, 255, 0) 0%
      );
      background-size: 8px 1px;
    }
    @if $dir == "x" {
      background-image: linear-gradient(
        to bottom,
        rgba($color, $ops) 33%,
        rgba(255, 255, 255, 0) 0%
      );
      width: 1px;
      height: 100%;
      background-repeat: repeat-y;
      background-size: 1px 5px;
      top: 0;
    }
  }
}

// fix it
@mixin border-bottom($color: #f36e2a, $size: 5px) {
  display: inline;
  background-image: linear-gradient($color, $color);
  background-position: left 95%;
  background-size: 100% $size;
  background-repeat: no-repeat;
}

@mixin text-shorten($numLines: 1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $numLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
  }
}