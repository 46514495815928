@import "@/assets/scss/header/_header-variables.scss";
/* Header */
#header {
    z-index: 1030;
    transition: all .2s ease-in-out;
    position: fixed;
    width: 100%;
    top: 0;
    // Body
    .header-body {
        display: flex;
        flex-direction: column;
        background: $header-background-color;
        transition: all .5s ease;
        width: 100%;
        border-top: $header-border-top;
        border-bottom: $header-border-bottom;
        z-index: 1001;
    }

    // Container
    .header-container {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        transition: ease height 300ms;
        height: $header-height;
    }

    // Grid Container
    .container {
        position: relative;
        @media (max-width: 767px) {
            width: 100%;
        }
    }

    // Row
    .header-row {
        display: flex;
        flex-grow: 1;
        align-items: center;
        align-self: stretch;
        max-height: 100%; // firefox
    }

    // Column
    .header-column {
        display: flex;
        align-self: stretch;
        align-items: center;
        flex-grow: 1;
        flex-direction: column;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;

        .header-row {
            justify-content: inherit;
        }

        &:last-child {
            .header-row {
                justify-content: end;
            }
        }
    }

    // Logo
    .header-logo {
        margin: 1rem 0;
        position: relative;
        z-index: 1;

        img {
            transition: $trns;
            position: relative;
            top: 0;
            image-rendering: -webkit-optimize-contrast;
            transform: translateZ(0);
            vertical-align: middle;
            &:last-child {
                opacity: 0;
                display: none;
            }
        }
    }

    // Header Nav
    .header-nav {
        padding: 1rem 0;
        min-height: 70px;
    }

    // Nav Top Features
    .header-nav-features {
        position: relative;
        padding-left: 20px;
        margin-left: 10px;
        border-left: 1px solid $header-separator-color;
        height: 100%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 992px) {
            border-left: 0 !important;
        }

        .header-nav-feature {
            position: relative;

            > a {
                text-decoration: none;
                color: $header-nav-link-color;
                font-size: $header-nav-link-font-size;
                font-weight: $header-nav-link-font-weight;
                transition: $trns;
                i {
                    color: $header-nav-link-color;
                    transition: $trns;
                }

                &:hover {
                    color: $header-nav-link-active-color;
                    transition: $trns;
                    i {
                        color: $header-nav-link-active-color;
                        transition: $trns;
                    }
                }
            }

            .dropdown-menu {
                border-radius: 0;
                padding: 0;

                li {
                    list-style: none;
                    padding: $header-dropdown-list-item;

                    a {
                        border-bottom: 1px solid rgba($primary, .2);
                        color: $header-dropdown-link-color;
                        font-weight: $header-dropdown-link-font-weight;
                        padding: $header-dropdown-link-padding;
                        position: relative;
                        font-size: $header-dropdown-link-font-size;
                        text-transform: none;
                        letter-spacing: -0.5px;
                        transition: $trns;

                        &:hover, &:focus, &.active, &:active {
                            background-color: transparent;
                        }
                    }

                    &:hover, &:focus, &.active, &:active, &.open, &.accessibility-open {
                        > a {
                            background-color: transparent;
                            color: $header-dropdown-link-active-color;
                        }
                    }
                }
            }
        }
    }

    // Collapse Nav
    .header-btn-collapse-nav {
        background: transparent;
        transition: all .3s ease-in-out;
        color: $header-nav-link-color;
        display: none;
        float: right;
        margin: 0 0 0 1rem;
    }
}

// Mobile Menu Opened
@media(max-width: 991px) {
    #header {
        .header-nav-menu {
            position: absolute;
            background: transparent;
            width: 100%;
            top: 100%;
            left: 50%;
            transform: translate3d(-50%, 0, 0);

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                height: 100%;
                background: $header-nav-menu-background-color;
                z-index: -1;
                transform: translateX(-50%);
                width: $header-nav-menu-width;
            }

            nav {
                max-height: 50vh;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 0 15px;
                transition: $trns;

                > ul {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    flex-direction: column;

                    li {
                        &.dropdown {
                            &.open {
                                > .dropdown-menu {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

        // Collapse Nav
        .header-btn-collapse-nav {
            outline: 0;
            display: block;
            position: relative;
            z-index: 1;
        }
    }
}

// Navigations
@import "@/assets/scss/header/_header-nav.scss";
@import "@/assets/scss/header/_header-nav-mobile.scss";
