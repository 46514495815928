
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.sub-nav-wrapper {
  background: $white;
  padding: 30px 30px 0;
  margin-bottom: 30px;
  box-shadow: $main-card-shadow;

  .wrapper {
    .details {
      display: flex;
      gap: 30px;

      .profile-img-wrapper {
        height: 124px;

        img {
          height: 100%;
        }
      }

      .title {
        margin: 10px 0;

        h4 {
          margin-bottom: 10px;
        }
      }

      @media screen and (max-width: 554px) {
        flex-direction: column;
        gap: 10px;
        .profile-img-wrapper {
          height: 54px;
        }
      }
    }
  }

  .nav-wrapper {
    margin-top: 30px;
    border-top: 1px dashed rgba(56, 68, 86, 0.2);

    #mainNav {
      gap: 10px 30px;
      flex-wrap: nowrap;
      overflow-x: scroll;
      width: 100%;

      .dropdown {
        padding: 17px 0;

        .dropdown-item {
          color: $gray;
          cursor: pointer;
          font-size: 14px;
          line-height: 16px;
          transition: $trns;

          &,
          svg {
            transition: $trns;
          }

          svg {
            height: 12px;
            width: auto;
          }

          &:hover,
          &.router-link-active {
            color: $primary;
            transition: $trns;

            &::before {
              width: 100%;
              transition: $trns;
            }

            svg {
              fill: $primary;
              transition: $trns;
            }
          }

          svg {
            margin-right: 8px;
          }

          &::before {
            content: "";
            width: 0;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: $trns;
            background-color: $primary;
          }

          &:hover,
          &.router-link-active,
          &.router-link-active img {
            color: $primary;

            &::before {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#mainNav {
  .dropdown {
    &.mobile {
      display: none;
      @media screen and (max-width: 1900px) {
        display: block;
      }
    }

    &.open {
      .dropdown-item.dropdown-toggle {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-item {
      transition: 0.3s ease;

      &.dropdown-toggle {
        @media screen and (max-width: 992px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            transition: 0.3s ease;
          }
        }

        &::after {
          content: unset;
        }
      }

      .menu-item-m-icon {
        display: none;
        @media screen and (max-width: 992px) {
          display: block;
        }
      }
    }

    @media screen and (max-width: 992px) {
      &:hover,
      &.open {
        > .dropdown-item {
          color: $primary;

          svg {
            color: $primary;
          }
        }
      }
      .dropdown-menu {
        flex-direction: column;
        gap: 31px;
        margin-top: 24px;
        padding: 0 0 0 15px;
        border: 0;
        > li {
          transition: $trns;
          &:not(:last-child) {
            position: relative;

            &::before {
              content: "";
              width: 100%;
              height: 1px;
              position: absolute;
              bottom: -15px;
              left: 0;
              background: $light;
            }
          }

          a {
            padding: 0;
            transition: 0.3s ease;

            &:hover {
              transition: $trns;
              color: $primary;
              background: unset;
            }

            svg {
              transition: $trns;
              display: none;
            }
          }
        }
      }
    }
  }
}

html {
  #header {
    .header-nav .header-nav-menu nav > ul > li {
      margin-left: 0;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &.dropdown .dropdown-menu {
        padding: 15px;

        li {
          &:hover {
            a {
              color: $primary;
            }

            svg {
              fill: $primary;
            }
          }

          &:not(:last-child) {
            padding-bottom: 15px;
            margin-bottom: 15px;
            position: relative;

            &::before {
              content: "";
              width: 100%;
              height: 1px;
              background: $light;
              position: absolute;
              bottom: 0;
              left: 0;
            }

            a {
              border-bottom: 0;

              svg {
                transition: $trns;
              }
            }
          }

          a {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      > a {
        padding-right: 0;

        &::before {
          content: "";
          width: 0;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: $trns;
          background: $white;
        }

        &:hover,
        &.router-link-active {
          &::before {
            width: 100%;
          }
        }
      }

      @media screen and (max-width: 1200px) {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    &.white {
      .header-nav .header-nav-menu nav > ul > li > a {
        color: $gray;
        position: relative;

        &.router-link-active {
          color: $primary;
        }

        &::before {
          background-color: $primary;
        }

        &:hover,
        &.router-link-active,
        &.router-link-active img {
          color: $primary;
        }
      }
    }
  }

  &.oly-sticky-active {
    #header .header-body .header-nav .header-nav-menu nav > ul > li.dropdown {
      .dropdown-item {
        color: $gray;

        &.router-link-active {
          color: $primary;
        }
      }

      .dropdown-menu li:not(:last-child) a {
        border-bottom: 0;
      }
    }
  }
}
