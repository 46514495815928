
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.chart {
  &.small {
    &.single {
      width: 65px;

      .text {
        font-size: 20px;
        transform: translate(-4px, 13px);

        tspan {
          color: #f3d32a;
        }
      }
    }

    &.multiple {
      width: 94px;

      text {
        line-height: 16px;
        transform: translate(-3px, 10px);
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  &.warning {
    &.single {
      .first-path {
        stroke: #eff0f2;
      }

      .fill-bar {
        stroke: #f3d32a;
        filter: drop-shadow(0px 0px 6px rgba(243, 211, 42, 0.4));
      }

      .text {
        fill: $warning;
        font-weight: bold;
        color: #f3d32a;
      }
    }

    &.multiple {
      path {
        &:nth-child(1) {
          stroke: rgba($warning, 0.2);
        }

        &:nth-child(2) {
          stroke: rgba($warning, 0.4);
        }

        &:nth-child(3) {
          stroke: rgba($warning, 0.5);
        }

        &:nth-child(4) {
          stroke: $warning;
          filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
        }
      }
    }
  }

  &.orange {
    path {
      &:first-child {
        stroke: rgba(#f8a87f, 0.12);
      }

      &:nth-child(2) {
        stroke: rgba(#f8a87f, 0.35);
      }

      &:nth-child(3) {
        stroke: rgba(#f8a87f, 0.75);
      }

      &:nth-child(4) {
        stroke: #fff;
        filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
      }
    }

    .text {
      fill: $white;
    }
  }

  &.orange-bar {
    &.single {
      .first-path {
        stroke: $light;
      }

      .fill-bar {
        stroke: $primary;
        filter: drop-shadow(0px 0px 6px rgba(243, 110, 42, 0.4));
      }

      .text {
        fill: $primary;
        font-weight: bold;
      }
    }

    path {
      &:first-child {
        stroke: rgba(#f8a87f, 0.12);
      }

      &:nth-child(2) {
        stroke: rgba(#f8a87f, 0.35);
      }

      &:nth-child(3) {
        stroke: rgba(#f8a87f, 0.75);
      }

      &:nth-child(4) {
        stroke: #fff;
      }
    }

    .text {
      fill: $white;
    }
  }

  &.light {
    path {
      &:first-child {
        stroke: rgba(#fff, .06);
      }

      &:nth-child(2) {
        stroke: rgba(#fff, 0.12);
      }

      &:nth-child(3) {
        stroke: rgba(#fff, 0.4);
      }

      &:nth-child(4) {
        stroke: #fff;
        filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
      }
    }

    .text {
      fill: $white;
    }
  }

  &.large {
    width: 235px;

    .text {
      font-size: 18px;
      transform: translate(-3px, 12px);

      tspan {
        color: #f3d32a;
      }
    }
  }

  &.zodiak {
    .text {
      font-family: Zodiak, $font-base-family;
    }
  }

  .text {
    fill: rgb(0, 0, 0);
    font-family: Arial, sans-serif;
    font-size: 9px;
    text-anchor: middle;
    white-space: pre;
  }
}
