/* Header Nav Main */
@media (min-width: 992px) {
    #header {
        .header-nav {
            display: flex;
            align-items: center;
            flex-grow: 1;
            justify-content: flex-end;
            align-self: stretch;
            padding: 0;

            .header-nav-menu {
                display: flex !important;
                height: auto !important;
                align-self: stretch;
                min-height: 0;
                margin-top: 0;

                nav {
                    display: flex !important;

                    > ul {
                        > li {
                            height: 100%;
                            align-self: stretch;
                            margin-left: 2px;
                            // Chrome Bug Fix
                            > a {
                                display: inline-flex;
                                align-items: center;
                                white-space: normal;
                                border-radius: $header-nav-link-radius;
                                color: $header-nav-link-color;
                                font-size: $header-nav-link-font-size;
                                font-style: normal;
                                font-weight: $header-nav-link-font-weight;
                                padding: $header-nav-link-padding;
                                letter-spacing: -0.5px;
                                text-transform: $header-nav-link-text-transform;
                                background-color: transparent;
                                height: 100%;
                                transition: $trns;
                                &:active {
                                    background-color: transparent;
                                    text-decoration: none;
                                    color: $header-nav-link-active-color;
                                }

                                &.dropdown-toggle {
                                    // when toggled
                                }

                                &:after {
                                    content: none;
                                }
                                > i {
                                    display: none;
                                }
                            }

                            &.open, &.accessibility-open, &:hover {
                                transition: $trns;
                                > a {
                                    transition: $trns;
                                    background: transparent;
                                    color: $header-nav-link-active-color;
                                }
                            }

                            &.dropdown {
                                .dropdown-menu {
                                    top: -10000px;
                                    display: block;
                                    opacity: 0;
                                    left: auto;
                                    // Chrome Bug Fix
                                    border-radius: 0;
                                    border: 0;
                                    margin: 0;
                                    min-width: 200px;
                                    //padding: 5px 20px;
                                    text-align: left;
                                    padding: $header-nav-menu-padding;
                                    background-color: $header-nav-menu-background-color;
                                    li {
                                        padding: $header-dropdown-list-item;

                                        a {
                                            color: $header-dropdown-link-color;
                                            font-weight: $header-dropdown-link-font-weight;
                                            padding: $header-dropdown-link-padding;
                                            position: relative;
                                            font-size: $header-dropdown-link-font-size;
                                            text-transform: none;
                                            letter-spacing: -0.5px;
                                            transition: $trns;

                                            &:hover, &:focus, &.active, &:active {
                                                background-color: transparent;
                                            }
                                        }

                                        &:not(:last-child) {
                                            a {
                                                border-bottom: 1px solid $header-dropdown-link-bottom-line-color;
                                            }
                                        }

                                        &:hover, &:focus, &.active, &:active, &.open, &.accessibility-open {
                                            > a {
                                                background-color: transparent;
                                                color: $header-dropdown-link-active-color;
                                            }
                                        }

                                        &.dropdown-submenu {
                                            position: relative;

                                            > a {
                                                .fa-chevron-down {
                                                    display: none;
                                                }

                                                &:after {
                                                    font-family: 'Font Awesome 6 Pro';
                                                    content: $dropdown-submenu-icon-content;
                                                    font-weight: $font-base-weight;
                                                    position: absolute;
                                                    top: 50%;
                                                    font-size: $dropdown-submenu-icon-size;
                                                    right: 20px;
                                                    transform: translateY(-50%);
                                                    transition: $trns;
                                                    border: 0;
                                                    color: $dropdown-submenu-icon-color;
                                                }
                                            }

                                            > .dropdown-menu {
                                                left: 100%;
                                                display: block;
                                                margin-top: -8px;
                                                margin-left: 0;
                                                border-radius: 0;
                                                opacity: 0;
                                                transform: translate3d(0, 8px, 0);
                                            }

                                            &.open, &.accessibility-open, &:hover {
                                                > a {
                                                    &:after {
                                                        right: 15px;
                                                        color: $dropdown-submenu-icon-active-color;
                                                    }
                                                }

                                                > .dropdown-menu {
                                                    top: 0;
                                                    opacity: 1;
                                                }
                                            }

                                            &.dropdown-reverse {
                                                &:hover > a {
                                                    &:after {
                                                        right: 30px;
                                                        transform: translateY(-50%) rotateY(180deg);
                                                    }
                                                }

                                                > .dropdown-menu {
                                                    left: auto;
                                                    right: 100%;
                                                    transform: translate3d(0, 0, 0);
                                                }
                                            }
                                        }

                                        &:last-child {
                                            a {
                                                border-bottom: 0;
                                            }
                                        }
                                    }
                                }

                                &.open, &.accessibility-open, &:hover {
                                    > a {
                                        border-radius: 0;
                                        position: relative;
                                    }

                                    > .dropdown-menu {
                                        top: auto;
                                        display: block;
                                        opacity: 1;
                                    }
                                }

                                &.dropdown-reverse {
                                    > .dropdown-menu {
                                        left: auto;
                                        right: 0;
                                    }
                                }
                            }

                            // Mega Menu
                            &.dropdown-mega {
                                position: static;

                                > .dropdown-menu {
                                    border-radius: 0;
                                    left: 15px;
                                    right: 15px;
                                    width: auto;
                                }

                                .dropdown-mega-content {
                                    padding: 1.6rem;
                                }

                                .dropdown-mega-sub-title {
                                    color: #333333;
                                    display: block;
                                    font-size: 0.9em;
                                    font-weight: 600;
                                    margin-top: 20px;
                                    padding-bottom: 10px;
                                    text-transform: none;
                                    letter-spacing: -0.5px;

                                    &:first-child {
                                        margin-top: 0;
                                    }
                                }

                                .dropdown-mega-sub-nav {
                                    list-style: none;
                                    padding: 0;
                                    margin: 0;

                                    > li {
                                        padding: 0;
                                        > a {
                                            border: 0 none;
                                            border-radius: 0;
                                            color: $header-dropdown-link-color;
                                            display: block;
                                            font-size: $header-dropdown-link-font-size;
                                            font-weight: $header-dropdown-link-font-weight;
                                            margin: 0;
                                            padding: $header-dropdown-link-padding;
                                            text-shadow: none;
                                            text-transform: none;
                                            text-decoration: none;
                                        }
                                        &:not(:last-child) {
                                            > a {
                                                border-bottom: 1px solid $header-dropdown-link-bottom-line-color;
                                            }
                                        }

                                        &:hover,
                                        &:active,
                                        &:focus {
                                            > a {
                                                background: transparent;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .dropdown-reverse {
                    a {
                        > .thumb-info-preview {
                            transform: translate3d(20px, 0, 0);
                            right: 100%;
                            left: auto;
                            padding-left: 0;
                            margin-right: 10px;
                        }

                        &:hover {
                            > .thumb-info-preview {
                                transform: translate3d(0, 0, 0);
                            }
                        }
                    }
                }
            }
        }
    }
}

// Header Nav Effect
@media (min-width: 992px) {
    #header {
        nav {
            > ul {
                > li {
                    &.dropdown {
                        .dropdown-menu, .dropdown-mega-sub-nav {
                            li {
                                a {
                                    transition: $trns;
                                    transform: translate3d(0, -5px, 0);
                                }
                            }
                        }

                        &:hover, &.open, &.accessibility-open {
                            > .dropdown-menu, .dropdown-mega-sub-nav {
                                li {
                                    a {
                                        transform: translate3d(0, 0, 0);
                                    }
                                }
                            }
                        }
                    }

                    &.dropdown {
                        .dropdown-menu {
                            transition: $trns;
                            transform: translate3d(0, -5px, 0);
                        }

                        &:hover, &.open, &.accessibility-open {
                            > .dropdown-menu {
                                transform: translate3d(0, 0, 0);
                            }
                        }
                    }
                }
            }
        }
    }
}

// Header Nav Sub Effect
@media (min-width: 992px) {
    #header {
        nav {
            > ul {
                > li {
                    &.dropdown {
                        .dropdown-menu {
                            li {
                                &.dropdown-submenu {
                                    > .dropdown-menu {
                                        transition: transform .2s ease-out, opacity .2s ease-out;
                                        transform: translate3d(-10px, 0, 0);
                                        opacity: 0;
                                    }

                                    &:hover, &.open, &.accessibility-open {
                                        > .dropdown-menu {
                                            transform: translate3d(0, 8px, 0);
                                            opacity: 1;
                                        }
                                    }

                                    &.dropdown-reverse {
                                        > .dropdown-menu {
                                            transition: transform .2s ease-out, opacity .2s ease-out;
                                            transform: translate3d(10px, 0, 0);
                                            left: auto;
                                            right: 100%;
                                            opacity: 0;
                                        }

                                        &:hover, &.open, &.accessibility-open {
                                            > .dropdown-menu {
                                                transform: translate3d(0, 0, 0);
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/*Set Color Based Different Header Class For Pages Except Index*/
// eq: other-page-header
#header.other-page-header {
    //@include header-type($reverse-header-type);
    .header-body {
        background-color: $reverse-header-background-color;

        .header-nav {
            .header-nav-menu {
                nav {
                    > ul {
                        > li {
                            > a {
                                color: $reverse-header-nav-link-color;

                                &:active {
                                    color: $reverse-header-nav-link-active-color;
                                }

                                &.dropdown-toggle {
                                    // when toggled
                                }

                                &:after {
                                    content: none;
                                }
                            }

                            &.open, &.accessibility-open, &:hover {
                                > a {
                                    color: $reverse-header-nav-link-active-color;
                                }
                            }
                        }
                    }
                }
            }
        }

        .header-btn-collapse-nav {
            color: $reverse-header-nav-link-color;
        }

        .header-nav-features {
            .header-nav-feature {
                > a {
                    color: $reverse-header-nav-link-color;

                    i {
                        color: $reverse-header-nav-link-color;
                    }

                    &:hover {
                        color: $reverse-header-nav-link-active-color;

                        i {
                            color: $reverse-header-nav-link-active-color;
                        }
                    }
                }
            }
        }
    }
}

// Sticky Header Active
html.oly-sticky-active {
    #header {
        .header-body {
            background-color: $stick-header-background-color;

            .header-nav {
                .header-nav-menu {
                    nav {
                        > ul {
                            > li {
                                > a {
                                    color: $sticky-header-nav-link-color;

                                    &:active {
                                        color: $sticky-header-nav-link-active-color;
                                    }

                                    &.dropdown-toggle {
                                        // when toggled
                                    }

                                    &:after {
                                        content: none;
                                    }
                                }

                                &.open, &.accessibility-open, &:hover {
                                    > a {
                                        color: $sticky-header-nav-link-active-color;
                                    }
                                }

                                &.dropdown {
                                    .dropdown-menu {
                                        background-color: $sticky-header-nav-menu-background-color;
                                        li {
                                            a {
                                                color: $sticky-header-dropdown-link-color;

                                                &:hover, &:focus, &.active, &:active {
                                                    background-color: transparent;
                                                }
                                            }

                                            &:hover, &:focus, &.active, &:active, &.open, &.accessibility-open {
                                                > a {
                                                    background-color: transparent;
                                                    color: $sticky-header-dropdown-link-active-color;
                                                }
                                            }

                                            &.dropdown-submenu {
                                                position: relative;

                                                > a {
                                                    .fa-chevron-down {
                                                        display: none;
                                                    }

                                                    &:after {
                                                        color: $sticky-dropdown-submenu-icon-color;
                                                    }
                                                }

                                                &.open, &.accessibility-open, &:hover {
                                                    > a {
                                                        &:after {
                                                            color: $dropdown-submenu-icon-active-color;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                // Mega Menu
                                &.dropdown-mega {
                                    .dropdown-mega-sub-title {
                                        color: $sticky-header-nav-link-color;
                                    }

                                    .dropdown-mega-sub-nav {
                                        > li {
                                            > a {
                                                color: $sticky-header-nav-link-color;
                                            }

                                            &:hover,
                                            &:active,
                                            &:focus {
                                                > a {
                                                    background: transparent;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .header-nav-features {
                .header-nav-feature {
                    > a {
                        color: $sticky-header-nav-link-color;

                        i {
                            color: $sticky-header-nav-link-color;
                        }

                        &:hover {
                            color: $sticky-header-nav-link-active-color;

                            i {
                                color: $sticky-header-nav-link-active-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
