
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.input-wrapper {
  position: relative;
  transition: $trns;

  .error-msg {
    position: absolute;
    width: 100%;
    bottom: -28px;
    font-size: 14px;
    color: red;
    font-family: inherit;
  }

  &.valid {
    border-color: $primary;
  }

  &.error {
    margin-bottom: 20px;

    input {
      border-color: red !important;
    }
  }

  &.socialLinkInput {
    &::before {
      content: "https://";
      position: absolute;
      padding: 16px 0;
      left: 16px;
      color: $gray;
      font-weight: 600;
    }

    input {
      padding-left: 80px;
    }
  }

  input {
    padding: 12px 36px 12px 16px;
    width: 100%;
    border: 1px solid $light;
    height: 58px;
    background: $lighter;
    color: $gray;
    transition: $trns;

    &:focus,
    &.valid {
      outline: none;
      border: 1px solid $primary;
      color: $dark;
      background: white;

      & + svg {
        fill: $primary;
      }
    }

    &:disabled {
      background: #eff0f2;
      border: 1px solid #eff0f2;
      cursor: not-allowed;
      user-select: none;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    transition: $trns;
  }
}
