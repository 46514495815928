
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

html {
  #header {
    .header-nav {
      justify-content: flex-start;
      padding-left: 27px;
      @media screen and (max-width: 992px) {
        display: flex;
        padding: 0;
        .header-btn-collapse-nav {
          margin-left: 0;
          border: 0;
          &:focus,
          &:active {
            outline: none;
            border: 0;
          }
          svg {
            &:first-child {
              display: block;
            }
            &:last-child {
              display: none;
            }
          }
        }
        &.right-nav {
          display: none;
        }
      }
    }
    &.white {
      .header-nav {
        @media screen and (max-width: 992px) {
          .header-btn-collapse-nav {
            svg {
              &:first-child {
                display: none;
              }
              &:last-child {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  &.oly-sticky-active {
    #header {
      .header-nav {
        min-height: unset;
        @media screen and (max-width: 992px) {
          padding-left: 0;
          .header-btn-collapse-nav {
            svg {
              &:first-child {
                display: none;
              }
              &:last-child {
                display: block;
              }
            }
          }
        }
        .header-nav-menu
          nav
          > ul
          > li.dropdown
          .dropdown-menu
          li:not(:last-child)
          a {
          border-bottom: 0;
        }
      }
    }
  }
}
