
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
