
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.textarea-wrapper {
  position: relative;

  textarea {
    background: $lighter;
    border: 1px solid $light;
    width: 100%;
    height: 200px;
    resize: none;
    padding: 12px 36px 12px 16px;

    &:focus {
      border: 1px solid $primary;
      outline: none;
      background-color: white;

      & + svg {
        fill: $primary;
      }
    }
  }

  svg {
    position: absolute;
    top: 21px;
    right: 16px;
  }
}
