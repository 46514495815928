
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.custom-checkbox {
  display: block;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 700;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked + label::after {
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      left: 9px;
      width: 6px;
      height: 14px;
      border: solid $primary;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  label {
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid $light;
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 12px;
      top: -2px;
    }

    a {
      transition: $trns;

      &:hover {
        color: currentColor;
      }
    }
  }

  &.cb-bg-secondary {
    input {
      &:checked {
        & + label {
          &::after {
            border-color: white;
            top: 1px;
          }

          &::before {
            background-color: $secondary;
          }
        }
      }
    }

    label {
      &::before {
        background: white;
        border: 1px solid $secondary;
      }
    }
  }
}
