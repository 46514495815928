
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.login {
  display: flex;
  padding: 10px;
  height: 100vh;

  .img-side {
    height: 100%;
    width: 45%;
    background: #F36E2A;
    position: relative;

    .texture {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .img-side-circle {
      width: 173px;
      height: 173px;
      border: 1px solid white;
      border-radius: 50%;
      position: absolute;
      top: 100px;
      right: 20px;
      z-index: 10;
    }

    .img-side-texture {
      width: 173px;
      height: 173px;
      background: white;
      opacity: .1;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      right: 88px;
    }

    .login-page-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  .login-side {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .content {
      width: 420px;
      @media screen and (max-width: 450px) {
        width: 350px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .img-side {
      display: none;
    }
  }
}
