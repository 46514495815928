
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.input-outer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    width: max-content;
    font-weight: 700;
  }
}
