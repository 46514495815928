.login {
  .vue-tel-input:focus-within {
    box-shadow: none !important;
  }
  .login-side {
    .content {
      .outer-wrapper {
        .inputs {
          .vue-tel-input {
            height: 58px;
            border: none;
            background: none;
            border-radius: 0px;
            width: 100%;

            .vti__dropdown {
              background: #fbfbfb;
              border: 1px solid #eff0f2;
              backdrop-filter: blur(6px);
              .vti__selection {
                .vti__dropdown-arrow {
                  margin-left: 10px;
                }

                .vti__country-code {
                  font-family: inherit;
                  font-size: 16px;
                  font-weight: 500;
                }
              }

              .vti__dropdown-list {
                width: 120px;
                margin-top: 15px;

                &::-webkit-scrollbar {
                  display: none;
                }

                scrollbar-width: none;

                .vti__dropdown-item {
                  strong {
                    display: none !important;
                  }
                }
              }
            }

            input {
              //display: none;
              border: 1px solid #eff0f2;
              background: #fbfbfb;
              backdrop-filter: blur(6px);
              margin-left: 16px;
            }
            input:focus {
              border: 1px solid $primary;
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
