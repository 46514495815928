
          @import "@/assets/scss/Style.scss";
          @import "@splidejs/vue-splide/css";
        

.action-buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
