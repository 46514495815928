/* Header Nav Main Mobile */
@media (max-width: 991px) {
    #header {
        .header-nav {
            .header-btn-collapse-nav {
                i {
                    color: $header-nav-link-color;
                }
            }
            .header-nav-menu {
                position: absolute;
                background: transparent;
                width: 100%;
                top: 100%;
                left: 50%;
                transform: translate3d(-50%, 0, 0);

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    background: $header-nav-menu-background-color;
                    z-index: -1;
                    transform: translateX(-50%);
                    transition: $trns;
                }

                nav {
                    max-height: 50vh;
                    overflow: hidden;
                    overflow-y: auto;
                    padding: 0 15px;
                    transition: ease all 500ms;

                    &.collapsing {
                        overflow-y: hidden;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                        -webkit-border-radius: 0;
                        border-radius: 0;
                        background: rgba(#CCC, 0.5);
                    }

                    > ul {
                        padding: $mobile-header-nav-menu-padding;
                        flex-direction: column;

                        li {
                            clear: both;
                            display: block;
                            float: none;
                            margin: 0;
                            padding: 0;
                            position: relative;

                            a {
                                font-size: $mobile-header-dropdown-link-font-size;
                                font-style: normal;
                                line-height: 20px;
                                padding: $mobile-header-dropdown-link-padding;
                                margin: 0;
                                border-radius: 0;
                                text-align: left;
                                text-transform: $mobile-header-dropdown-link-text-transform;
                                color: $mobile-header-dropdown-link-color;
                                background-color: transparent;
                                transition: $trns;
                                border-bottom: 1px solid $header-dropdown-link-bottom-line-color;
                                .fa-caret-down {
                                    line-height: 35px;
                                    min-height: 38px;
                                    min-width: 30px;
                                    position: absolute;
                                    right: 5px;
                                    text-align: center;
                                    top: 0;
                                }

                                &.dropdown-toggle {
                                    position: relative;

                                    &:after {
                                        content: none;
                                    }

                                    .fa-chevron-down {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        width: 30px;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: $mobile-header-dropdown-toggle-icon-size;
                                        color: $mobile-header-dropdown-link-color;
                                    }
                                }

                                &:active {
                                    background-color: transparent;
                                    color: inherit;
                                }

                                &.open, &.accessibility-open, &:hover {
                                    > a {
                                        background: transparent;
                                        color: $header-nav-link-active-color;
                                    }
                                }
                            }

                            &.dropdown {
                                .dropdown-menu {
                                    background: transparent;
                                    padding: 0;
                                    margin: 0;
                                    font-size: $mobile-header-dropdown-link-font-size;
                                    box-shadow: none;
                                    border-radius: 0;
                                    border: 0;
                                    clear: both;
                                    display: none;
                                    float: none;
                                    position: static;

                                    li {
                                        &.dropdown-submenu {
                                            > a {
                                                position: relative;

                                                &:after {
                                                    content: none;
                                                }

                                                .fa-chevron-down {
                                                    position: absolute;
                                                    top: 0;
                                                    right: 0;
                                                    width: 30px;
                                                    height: 100%;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    font-size: $mobile-header-dropdown-toggle-icon-size;
                                                }
                                            }

                                            &.open {
                                                > .dropdown-menu {
                                                    display: block;
                                                    margin-left: 20px;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.open {
                                    > .dropdown-menu {
                                        display: block;
                                        //margin-left: 20px;
                                    }
                                }
                            }

                            &.dropdown-mega {
                                .dropdown-mega-sub-title {
                                    margin-top: 10px;
                                    display: block;
                                    text-align: left;
                                }

                                .dropdown-mega-sub-nav {
                                    margin: 0 0 0 20px;
                                    padding: 0;
                                    list-style: none;

                                    > li {
                                        > a {
                                            display: block;
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                border-bottom: 0;
                            }
                        }

                        > li {
                            > a {
                                text-transform: uppercase;
                                font-weight: $mobile-header-dropdown-link-font-weight;
                                color: $mobile-header-dropdown-link-color;

                                &.active,&:focus, &:hover  {
                                    color: $header-dropdown-link-active-color !important;
                                    background: transparent;
                                }
                            }
                        }
                    }

                    .not-included {
                        margin: 0;
                    }
                }
            }
        }
    }

    html.oly-sticky-active {
        #header {
            .header-nav {
                .header-nav-menu {
                    &:before {
                        width: 100vw;
                        background-color: $sticky-header-nav-menu-background-color;
                    }
                }

                > ul {
                    li {
                        a {
                            color: $sticky-mobile-header-dropdown-link-color;
                        }
                    }
                }

                .header-btn-collapse-nav {
                    i {
                        color: $sticky-mobile-header-dropdown-toggle-icon-color;
                    }
                }
            }
        }
    }
}
